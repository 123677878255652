<template>
    <v-container>
        <DlgMessage ref="dlgMsg" @dlgClose="dlgClose"></DlgMessage>

        <div class="mb-3">
          <v-toolbar>
            <v-btn icon class="hidden-xs-only" @click="$router.back()"><v-icon>mdi-arrow-left</v-icon></v-btn>
            <v-toolbar-title>Transaction Detail</v-toolbar-title>
            <v-spacer></v-spacer>
            <v-breadcrumbs :items="items"></v-breadcrumbs>
          </v-toolbar>
        </div> 
        <v-row class="mt-3">
           <v-col cols="12" md="12">
                <v-card>
                    <v-toolbar dark color="panel1">
                        <v-toolbar-title>Customer Info</v-toolbar-title>
                    </v-toolbar>
                    <v-container class="pt-2 mt-3">
                        <v-row>
                            <v-col cols="12" md="12">
                                <v-text-field readonly v-for="(info,index) in  custInfo" :key="'custIndex'+index" outlined v-model="custDetail[info.fieldName]" :label="info.name"></v-text-field>
                            </v-col>
                        </v-row>

                    </v-container>
                
                </v-card>

                <v-toolbar  dark color="panel2" class="mt-5"><v-toolbar-title>Point Issuance List</v-toolbar-title>
                </v-toolbar>

                    <v-container class="my-5">
                        <v-card  v-for="(tx,index) in txList" :key="'index-'+index">
                            <v-row class="pa-3">
                            <v-col xs6 md6>
                                <div class="caption grey--text">Code:{{ tx.em_code }}</div>
                                <div>{{ tx.em_name }}</div>
                            </v-col>
                            <v-col xs6 md6>
                                <div class="text-right caption grey--text">Points</div>
                                <div class="text-right">{{ tx.points }}</div>
                            </v-col>
                            </v-row>
                            
                                
                            <v-divider></v-divider>
                        </v-card>
                    </v-container>                  
            </v-col>
            <!--image-->
            <v-col cols="12" md="12">
                <v-card>
                    <v-toolbar dark color="panel3">
                        <v-toolbar-title>Transaction Info</v-toolbar-title>
                    </v-toolbar>

                    <v-container class="pt-2 mt-3">
                        <v-row>
                            <v-col cols="12" md="12">
                                <v-text-field readonly v-for="(info,index) in  txInfo" :key="info.name + index" outlined v-model="txDetail[info.fieldName]" :label="info.name"></v-text-field>
                            </v-col>
                        </v-row>
                    </v-container>
                </v-card>
            </v-col>

        </v-row>

    </v-container>  
</template>
    
<script>
import DlgMessage from '@/components/DlgMessage'
import ApTransactionService from "@/services/ApTransactionService"
export default {
  components:{DlgMessage},
  props: {
       caller: String
    },
  data() {
    return{
            //breadcrumb
            items: [
              {text:'Transaction',disabled: false,href:'/',},
              {text:'Detail',disabled: false,href:'#',},
              ],
            statusList:[],
            form:[],
            custDetail:[],
            custInfo:[
                {name:"Login Id",fieldName:"login_id"},
                {name:"Full Name",fieldName:"full_name"},
                {name:"Cell Phone No.",fieldName:"cell_phone"},
                {name:"Email Address.",fieldName:"email"},
            ],
            txList:[],
            txDetail:[],
            txInfo:[
                {name:"Process Reference No.",fieldName:"process_reference_no"},
                {fieldName:"merchant_name",name:"Merchant Name"},
                {fieldName:"outlet_name",name:"Outlet Name"},
                {fieldName:"edc_login_id",name:"Terminal ID"},
                {fieldName:"transaction_date",name:"Transaction Date"},
                {fieldName:"process_date",name:"Process Date"},
                {fieldName:"reference_no",name:"Reference No"},
                {fieldName:"process_amount",name:"Process Amount"},
                {name:"Created Date",fieldName:"created_date"},
                {name:"Created At",fieldName:"created_at"},
                {name:"Created By",fieldName:"created_by"},
                {name:"Updated Date",fieldName:"updated_date"},
                {name:"Updated At",fieldName:"updated_at"},
                {name:"Updated By",fieldName:"updated_by"},
            ],
            routeParam:[],
    }
  },
  async created() {
      this.routeParam['process_reference_no'] = this.$route.params.process_reference_no;
      this.viewDetail();
  },
  
  methods:{

    async viewDetail(){
        let request = {process_reference_no:this.routeParam['process_reference_no']}
        try{
            ApTransactionService.view_transaction(request).then((res) => {
                this.custDetail = res.data.custData;
                this.txList = res.data.data;
                if(this.txList[0]){
                    this.txDetail = this.txList[0];
                    console.log(this.txDetail)
                }

            }).catch((e)=>{
                console.log(e.response);
                this.$refs.dlgMsg.showDlgBox(true,'error','Merchant',e.response);
            })
            ;
        } catch(e) {
            console.log(e);
            //this.$refs.dlgMsg.showDlgBox(true,'error','Supplier','Unknown Error');
        }

    },
    dlgClose(){
        this.visible = false;        
    },
  }
}
</script>